import { Component, BaseComponent, Prop, namespace } from '@zento-lib/components';
import type { I18nMessage } from '@zento-lib/components/Base/types';
import placeholderImg from 'theme/assets/placeholder.svg';
import { AppContextStore, KEY as appKey } from 'theme/@types/zento/stores/applicationContext';

import type { ImageTypes } from '../types/ImageTypes';

import type { IMainImage } from './MainImage.d';
import style from './style.scss';

const appContextStore = namespace<AppContextStore>(appKey);

/**
 * Image
 *
 * Image component allowing to create picture tag for different screen resolutions.
 **/
@Component({})
export class MainImage extends BaseComponent<IMainImage, unknown> {
  /**
   * Image object to be rendered
   */
  @Prop({ type: Object, required: true })
  image: ImageTypes;

  /**
   * Specifies an alternate text for an image, if the image can not be displayed.
   */
  @Prop({ type: String, default: '' })
  alt?: I18nMessage;

  /**
   * Specifies the image width used for mobile devices
   */
  @Prop({ type: Number, default: 150 })
  width?: number;

  /**
   * Specifies the image height used for mobile devices
   */
  @Prop({ type: Number, default: 200 })
  height?: number;

  /**
   * Specifies the image width used for tablet devices
   */
  @Prop({ type: Number, default: 200 })
  tabletWidth?: number;

  /**
   * Specifies the image height used for tablet devices
   */
  @Prop({ type: Number, default: 266 })
  tabletHeight?: number;

  /**
   * Specifies the image width used for desktop devices
   */
  @Prop({ type: Number, default: 300 })
  desktopWidth?: number;

  /**
   * Specifies the image height used for desktop devices
   */
  @Prop({ type: Number, default: 400 })
  desktopHeight?: number;

  /**
   * Specifies the image height fallback
   */
  @Prop({ type: Number, default: null })
  heightFallback?: number;

  /**
   * Indicates the media folder where the specific image can be found
   */
  @Prop({ type: String, default: '/media/catalog/product' })
  folder?: string;

  /**
   * Indicates image resize: fill / crop or empty string (default)
   */
  @Prop({ type: String, default: 'xf' })
  resize?: string;

  /**
   * Indicates image load type
   */
  @Prop({ type: String, required: false, default: 'lazy' })
  loadingType?: 'lazy' | 'eager' | 'auto';

  /**
   * An event that will carry out the process when the conditions inherent to the event they're associated with, are met
   */
  @Prop({ type: Function, default: () => undefined })
  handler?: (ev: Event) => void;

  @appContextStore.Getter('isDesktop')
  private isDesktop: boolean;

  @appContextStore.Getter('isTablet')
  private isTablet: boolean;

  @appContextStore.Getter('isMobile')
  private isMobile: boolean;

  // Indicates the start of mobile devices
  private static mobileMedia = 320;

  // Indicates the start of tablet devices
  private static tabletMedia = 767;

  // Indicates the start of large tablets and above devices
  private static tabletLargeMedia = 1025;

  beforeMount() {
    this.showPlaceholder = this.showPlaceholder.bind(this);
  }

  get mobileImagePath() {
    return (
      `${this.extended.$config.images.baseUrl}cache/${this.width}x${
        (this.heightFallback !== null ? this.heightFallback : this.height) + this.resize
      }` + `${this.folder}${this.trimFileExtension}`
    );
  }

  get retinaMobileImagePath() {
    return (
      `${this.extended.$config.images.baseUrl}cache/${this.width * 2}x${
        (this.heightFallback !== null ? this.heightFallback : this.height) * 2 + this.resize
      }` + `${this.folder}${this.trimFileExtension}`
    );
  }

  get tabletImagePath() {
    return (
      `${this.extended.$config.images.baseUrl}cache/${this.tabletWidth}x${
        (this.heightFallback !== null ? this.heightFallback : this.tabletHeight) + this.resize
      }` + `${this.folder}${this.trimFileExtension}`
    );
  }

  get retinaTabletImagePath() {
    return (
      `${this.extended.$config.images.baseUrl}cache/${this.tabletWidth * 2}x${
        (this.heightFallback !== null ? this.heightFallback : this.tabletHeight) * 2 + this.resize
      }` + `${this.folder}${this.trimFileExtension}`
    );
  }

  get imagePath() {
    return (
      `${this.extended.$config.images.baseUrl}cache/${this.desktopWidth}x${
        (this.heightFallback !== null ? this.heightFallback : this.desktopHeight) + this.resize
      }` + `${this.folder}${this.trimFileExtension}`
    );
  }

  get retinaImagePath() {
    return (
      `${this.extended.$config.images.baseUrl}cache/${this.desktopWidth * 2}x${
        (this.heightFallback !== null ? this.heightFallback : this.desktopHeight) * 2 + this.resize
      }` + `${this.folder}${this.trimFileExtension}`
    );
  }

  private get SVGFiller() {
    if (this.isMobile) {
      return `data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='${this.width}' height='${this.height}'></svg>`;
    } else if ((this.isTablet && !this.isMobile) || !this.isDesktop) {
      return `data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='${this.tabletWidth}' height='${this.tabletHeight}'></svg>`;
    } else if (this.isDesktop) {
      return `data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='${this.desktopWidth}' height='${this.desktopHeight}'></svg>`;
    }
  }

  private get imageExtension() {
    return '.' + this.image.src.split('.').pop();
  }

  private get trimFileExtension() {
    return this.image.src
      .split(/\.(?=[^.]+$)/)
      .slice(0, -1)
      .join('');
  }

  /**
   * Method to handle image error
   */
  showPlaceholder(ev) {
    if (ev && ev.target && ev.target.parentNode && ev.target.parentNode.children) {
      // Convert children NodeList to an actual array (allows using array methods on all browsers)
      Array.from(ev.target.parentNode.children as HTMLImageElement[]).forEach((children) => {
        if (children.tagName === 'SOURCE') {
          children.srcset = '';
          (children as any).type = 'image/svg+xml';
        } else if (children.tagName === 'IMG') {
          children.srcset = '';
          children.src = placeholderImg;
        }
      });
    } else {
      console.info('Unable to find contained image for placeholder replacement');
    }
  }

  render() {
    return (
      <div class={style.imageContainer}>
        {/* Filler image respecting original image aspect ratio */}
        {/* (allowing the browser to occupy required blank space at initial render time) */}
        <img
          src={this.SVGFiller}
          alt={this.alt}
          loading={this.loadingType}
          onError={this.showPlaceholder}
          class={style.placeholderBlockImage}
        />
        {/* Picture container */}
        {this.image.src !== undefined && this.image.src !== null && this.image.src !== '' ? (
          // Use the picture when available
          <picture onClick={this.handler} class={style.image}>
            <source
              media={'(min-width:' + MainImage.mobileMedia + 'px) and (max-width:' + MainImage.tabletMedia + 'px)'}
              srcset={this.mobileImagePath + '.webp 1x, ' + this.retinaMobileImagePath + '.webp 2x'}
              type='image/webp'
            />
            <source
              media={'(min-width:' + MainImage.tabletMedia + 'px) and (max-width:' + MainImage.tabletLargeMedia + 'px)'}
              srcset={this.tabletImagePath + '.webp 1x, ' + this.retinaTabletImagePath + '.webp 2x'}
              type='image/webp'
            />
            <source
              media={'(min-width:' + MainImage.tabletLargeMedia + 'px)'}
              srcset={this.imagePath + '.webp 1x, ' + this.retinaImagePath + '.webp 2x'}
              type='image/webp'
            />
            <source
              media={'(min-width:' + MainImage.mobileMedia + 'px) and (max-width:' + MainImage.tabletMedia + 'px)'}
              srcset={
                this.mobileImagePath +
                this.imageExtension +
                ' 1x, ' +
                this.retinaMobileImagePath +
                this.imageExtension +
                ' 2x'
              }
              type='image/jpg'
            />
            <source
              media={'(min-width:' + MainImage.tabletMedia + 'px) and (max-width:' + MainImage.tabletLargeMedia + 'px)'}
              srcset={
                this.tabletImagePath +
                this.imageExtension +
                ' 1x, ' +
                this.retinaTabletImagePath +
                this.imageExtension +
                ' 2x'
              }
              type='image/jpg'
            />
            <source
              media={'(min-width:' + MainImage.tabletLargeMedia + 'px)'}
              srcset={
                this.imagePath + this.imageExtension + ' 1x, ' + this.retinaImagePath + this.imageExtension + ' 2x'
              }
              type='image/jpg'
            />
            <img
              src={this.mobileImagePath + this.imageExtension}
              srcset={
                this.mobileImagePath +
                this.imageExtension +
                ' 1x, ' +
                this.retinaMobileImagePath +
                this.imageExtension +
                ' 2x'
              }
              alt={this.alt}
              loading={this.loadingType}
              onError={this.showPlaceholder}
            />
          </picture>
        ) : (
          // Fallback to placeholder otherwise
          <div class={style.image}>
            {/* No image placeholder */}
            <img src={placeholderImg} alt={this.alt} loading={this.loadingType} />
          </div>
        )}
        <slot name='after' />
      </div>
    );
  }
}
